(function( $ ) {

    $.fn.dropdownMobileMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var openLink = jqContainer.find('>a').first();
            openLink.click(function(e) {
                e.preventDefault();
                if (jqContainer.hasClass('open')) {
                    jqContainer.removeClass('open');
                } else {
                    jqContainer.addClass('open');
                }
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-dropdown-init').dropdownMobileMenu();
});
